import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {LR} from '../../../helpers/Urls';

export const FETCH_ALL_LR = 'FETCH_ALL_LR';
export const FETCH_ALL_LR_COMPLETED = 'FETCH_ALL_LR_COMPLETED';
export const FETCH_ALL_LR_FAILED = 'FETCH_ALL_LR_FAILED';

export const DELETE_LR = 'DELETE_LR';
export const DELETE_LR_COMPLETED = 'DELETE_LR_COMPLETED';
export const DELETE_LR_FAILED = 'DELETE_LR_FAILED';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  lr_ids: [],
};

export const fetchAllLR = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ALL_LR});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: LR.fetchAllLRURL(tripId),
      });
      console.log('response --FETCH_ALL_LR--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_ALL_LR_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_ALL_LR_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ALL_LR_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteLR = (tripId, lrId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_LR});
      response = await doRequest({
        method: REQUEST_TYPE.DELETE,
        url: LR.deleteLorryReceiptURL(tripId),
        data: {lr_id: lrId},
      });
      console.log('response --DELETE_LR--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: DELETE_LR_COMPLETED, payload: {...data}});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: DELETE_LR_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: DELETE_LR_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_ALL_LR:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_ALL_LR_COMPLETED:
    case DELETE_LR_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        lr_ids: payload.lorry_reciept_ids,
        lorry_reciepts: payload.lorry_reciepts,
      };
    case FETCH_ALL_LR_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    default:
      return state;
  }
};
