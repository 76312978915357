export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.lorry_reciept_ids) {
        return payload.lorry_reciept_ids;
      }
      return state;
    }
  }
};
