import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE, RECORD_TYPE} from '../../../constants';
import * as _ from 'lodash';
import {Pod} from '../../../helpers/Urls';

const INITIAL_STATE = {
  file_names: [],
  open_trips: [],
  wrong_files: [],
  resetPage: false,
  isFetching: false,
  isFetchingStatus: false,
  hasError: false,
  error: '',
  trips_validated: [],
  bulkUploadPodFilesLength: '',
};

export const BULK_UPLOAD_POD = 'BULK_UPLOAD_POD';
export const BULK_UPLOAD_POD_COMPLETED = 'BULK_UPLOAD_POD_COMPLETED';
export const BULK_UPLOAD_POD_FAILED = 'BULK_UPLOAD_POD_FAILED';

export const BULK_UPDATE_POD_STATUS = 'BULK_UPDATE_POD_STATUS';
export const BULK_UPDATE_POD_STATUS_COMPLETED =
  'BULK_UPDATE_POD_STATUS_COMPLETED';
export const BULK_UPDATE_POD_STATUS_FAILED = 'BULK_UPDATE_POD_STATUS_FAILED';
const RESET_PAGE = 'RESET_PAGE';
export const BULK_UPLOAD_POD_FILES_LENGTH_TRUE =
  'BULK_UPLOAD_POD_FILES_LENGTH_TRUE';
export const BULK_UPLOAD_POD_FILES_LENGTH_FALSE =
  'BULK_UPLOAD_POD_FILES_LENGTH_FALSE';
export const FETCH_BULKUPLOAD_DETAILS_FAILED =
  'FETCH_BULKUPLOAD_DETAILS_FAILED';

export const FETCH_BULKUPLOAD_DETAILS_COMPLETED =
  'FETCH_BULKUPLOAD_DETAILS_COMPLETED';

export const FETCH_BULKUPLOAD_DETAILS = 'FETCH_BULKUPLOAD_DETAILS';

export const bulkUploadPODRequest = (requestData = {}, resetPage) => {
  return async (dispatch) => {
    let response = {};
    console.log('OUTSIDE TRY');
    try {
      dispatch({type: BULK_UPLOAD_POD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Pod.postbulkuploadURL(),
        data: requestData,
      });

      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status) {
        dispatch({
          type: BULK_UPLOAD_POD_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: BULK_UPLOAD_POD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      console.log('error inside api', err);

      dispatch({
        type: BULK_UPLOAD_POD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const bulkUpdatePodStatus = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: BULK_UPDATE_POD_STATUS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Pod.postbulkupdatestatusURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: BULK_UPDATE_POD_STATUS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: BULK_UPDATE_POD_STATUS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      console.log('error inside api', err);

      dispatch({
        type: BULK_UPDATE_POD_STATUS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const bulkUploadPodFiles = (data = '') => {
  return (dispatch) => {
    if (data) {
      dispatch({type: BULK_UPLOAD_POD_FILES_LENGTH_TRUE, payload: data});
    } else {
      dispatch({type: BULK_UPLOAD_POD_FILES_LENGTH_FALSE});
    }
  };
};

export const sendData = (value) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: '/trip/edit-trip-shortage',
        data: value,
      });
      const {data, status} = response || {};
      if (status) {
      } else {
        const {message = 'Service Error'} = response || {};
      }
    } catch (err) {
      console.log('error inside api', err);
    }
    return response;
  };
};

export const bulkUploadPodDetails = () => {
  return async (dispatch) => {
    let response = {};

    try {
      dispatch({type: FETCH_BULKUPLOAD_DETAILS});

      response = await doRequest({
        method: REQUEST_TYPE.GET,

        url: Pod.bulkUploadPodDetailsURL(),
      });

      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);

      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: FETCH_BULKUPLOAD_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};

        dispatch({
          type: FETCH_BULKUPLOAD_DETAILS_FAILED,

          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BULKUPLOAD_DETAILS_FAILED,

        payload: {error: err.message},
      });
    }

    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case BULK_UPLOAD_POD:
      return {
        ...state,
        error: '',
        isFetching: true,
        hasError: false,
      };
    case BULK_UPLOAD_POD_COMPLETED:
      return {
        ...state,
        file_names: payload?.file_names,
        open_trips: payload?.open_trips,
        wrong_files: payload?.wrong_files,
        isFetching: false,
        hasError: false,
      };
    case BULK_UPLOAD_POD_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.message,
        file_names: [],
        open_trips: [],
        wrong_files: [],
      };
    case BULK_UPDATE_POD_STATUS:
      return {
        ...state,
        error: '',
        isFetching: true,
        hasError: false,
      };
    case BULK_UPDATE_POD_STATUS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        trips_validated: payload?.trips_validated,
      };
    case BULK_UPDATE_POD_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.message,
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    case BULK_UPLOAD_POD_FILES_LENGTH_TRUE:
      return {
        ...state,
        bulkUploadFilesLength: payload,
      };
    case BULK_UPLOAD_POD_FILES_LENGTH_FALSE:
      return {
        ...state,
        bulkUploadFilesLength: '',
      };
    case FETCH_BULKUPLOAD_DETAILS:
      return {
        ...state,

        // isFetching: true,

        isFetchingStatus: true,

        hasError: false,
      };

    case FETCH_BULKUPLOAD_DETAILS_COMPLETED:
      return {
        ...state,

        // isFetching: false,

        isFetchingStatus: false,

        hasError: false,

        success_upload: payload?.success_upload,

        failed_upload: payload?.failed_upload,

        pending_state: payload?.pending_state,
      };

    case FETCH_BULKUPLOAD_DETAILS_FAILED:
      return {
        ...state,

        // isFetching: false,

        isFetchingStatus: false,

        hasError: true,
      };

    default:
      return state;
  }
};
