import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Tracking} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  fence: null,
  fenceIds: [],
  fences: {},
  search: '',
  fenceCount: null,
  isRequesting: false,
  resetPage: false,
};

export const FETCH_FENCE_DETAILS = 'FETCH_FENCE_DETAILS';
export const FETCH_FENCE_DETAILS_COMPLETED = 'FETCH_FENCE_DETAILS_COMPLETED';
export const FETCH_FENCE_DETAILS_FAILED = 'FETCH_FENCE_DETAILS_FAILED';

export const DELETE_GEO_FENCE = 'DELETE_GEO_FENCE';
export const DELETE_GEO_FENCE_COMPLETED = 'DELETE_GEO_FENCE_COMPLETED';
export const DELETE_GEO_FENCE_FAILED = 'DELETE_GEO_FENCE_FAILED';

export const FETCH_FENCE_BY_ID = 'FETCH_FENCE_BY_ID';
export const FETCH_FENCE_BY_ID_COMPLETED = 'FETCH_FENCE_BY_ID_COMPLETED';
export const FETCH_FENCE_BY_ID_FAILED = 'FETCH_FENCE_BY_ID_FAILED';

export const UPDATE_FENCE_DETAILS = 'UPDATE_FENCE_DETAILS';
export const UPDATE_FENCE_DETAILS_COMPLETED = 'UPDATE_FENCE_DETAILS_COMPLETED';
export const UPDATE_FENCE_DETAILS_FAILED = 'UPDATE_FENCE_DETAILS_FAILED';

const FILTER_GEO_FENCE_DETAILS_FALSE = 'FILTER_GEO_FENCE_DETAILS_FALSE';
const FILTER_GEO_FENCE_DETAILS_TRUE = 'FILTER_GEO_FENCE_DETAILS_TRUE';
const SET_CURRENT_GEO_FENCE_DATA = 'SET_CURRENT_GEO_FENCE_DATA';

const RESET_PAGE = 'RESET_PAGE';

export const fetchFenceById = (fenceId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_FENCE_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.updateFenceDetailsURL(fenceId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_FENCE_BY_ID_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_FENCE_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_FENCE_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteFence = (fenceId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_GEO_FENCE});
      response = await doRequest({
        method: REQUEST_TYPE.DELETE,
        url: Tracking.addFenceDetailsURL(),
        data: {gid: fenceId},
      });
      console.log('response --DELETE_FENCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: DELETE_GEO_FENCE_COMPLETED, payload: {...data}});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: DELETE_GEO_FENCE_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: DELETE_GEO_FENCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addFenceDetails = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_FENCE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tracking.addFenceDetailsURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_FENCE_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_FENCE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_FENCE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const filteredData = (searchInput = '', filteredTrips = []) => (
  dispatch,
) => {
  if (!searchInput) {
    dispatch({
      type: FILTER_GEO_FENCE_DETAILS_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_GEO_FENCE_DETAILS_TRUE,
    payload: searchInput,
  });
};

export const currentTableData = (tableData = []) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_GEO_FENCE_DATA,
    payload: tableData,
  });
};

export const fetchFenceDetails = (search = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_FENCE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.fetchFenceDetailsURL() + search,
      });
      console.log('response --FETCH_FENCE_DETAILS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_FENCE_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_FENCE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_FENCE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_FENCE_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_FENCE_DETAILS_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetching: false,
        hasError: false,
        fenceIds: payload?.geofence_ids,
        fenceCount: payload?.geofence_ids?.length,
        fences: payload?.geofences,
      };
    case FETCH_FENCE_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        searching: '',
        error: payload.error,
        fenceIds: [],
        fences: {},
      };
    case SET_CURRENT_GEO_FENCE_DATA:
      return {
        ...state,
        currentTableData: payload,
      };
    case FILTER_GEO_FENCE_DETAILS_FALSE:
      return {
        ...state,
        searching: '',
      };
    case FILTER_GEO_FENCE_DETAILS_TRUE:
      return {
        ...state,
        searching: payload,
      };
    case FETCH_FENCE_BY_ID:
      return {...state, isFetching: true};
    case FETCH_FENCE_BY_ID_COMPLETED:
      return {
        ...state,
        isFetching: false,
        fence: payload,
      };
    case FETCH_FENCE_BY_ID_FAILED:
      return {...state, isFetching: false};
    case UPDATE_FENCE_DETAILS:
      return {...state, isRequesting: true};
    case UPDATE_FENCE_DETAILS_COMPLETED:
    case UPDATE_FENCE_DETAILS_FAILED:
      return {...state, isRequesting: false};
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    default:
      return state;
  }
};
