import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Upload} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingLatest: false,
  isRequestingUpload: false,
  hasError: false,
  error: '',
  hasUploadError: false,
  uploadError: '',
  hasLatestError: false,
  latestError: '',
  bulkUploadData: null,
  latestUploadsData: null,
};

export const FETCH_BULK_UPLOAD_TABS = 'FETCH_BULK_UPLOAD_TABS';
export const FETCH_BULK_UPLOAD_TABS_COMPLETED =
  'FETCH_BULK_UPLOAD_TABS_COMPLETED';
export const FETCH_BULK_UPLOAD_TABS_FAILED = 'FETCH_BULK_UPLOAD_TABS_FAILED';

export const FETCH_LATEST_UPLOADS = 'FETCH_LATEST_UPLOADS';
export const FETCH_LATEST_UPLOADS_COMPLETED = 'FETCH_LATEST_UPLOADS_COMPLETED';
export const FETCH_LATEST_UPLOADS_FAILED = 'FETCH_LATEST_UPLOADS_FAILED';

export const BULK_UPLOAD_DOCUMENTS = 'BULK_UPLOAD_DOCUMENTS';
export const BULK_UPLOAD_DOCUMENTS_COMPLETED =
  'BULK_UPLOAD_DOCUMENTS_COMPLETED';
export const BULK_UPLOAD_DOCUMENTS_FAILED = 'BULK_UPLOAD_DOCUMENTS_FAILED';

export const fetchBulkUploadTabs = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_BULK_UPLOAD_TABS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Upload.fetchBulkUploadTabsURL(),
      });
      console.log('response --FETCH_BULK_UPLOAD_TABS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_BULK_UPLOAD_TABS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_BULK_UPLOAD_TABS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BULK_UPLOAD_TABS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchLatestUploads = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_LATEST_UPLOADS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Upload.fetchLatestUploadsURL(),
      });
      console.log('response --FETCH_LATEST_UPLOADS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_LATEST_UPLOADS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_LATEST_UPLOADS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_LATEST_UPLOADS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const bulkUploadDocuments = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: BULK_UPLOAD_DOCUMENTS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Upload.bulkUploadDocumentsURL(),
        data: requestData,
      });

      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: BULK_UPLOAD_DOCUMENTS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: BULK_UPLOAD_DOCUMENTS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      console.log('error inside api', err);

      dispatch({
        type: BULK_UPLOAD_DOCUMENTS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_BULK_UPLOAD_TABS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_BULK_UPLOAD_TABS_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetching: false,
        hasError: false,
        bulkUploadData: payload,
      };
    case FETCH_BULK_UPLOAD_TABS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        bulkUploadData: null,
      };
    case FETCH_LATEST_UPLOADS:
      return {
        ...state,
        isFetchingLatest: true,
        hasLatestError: false,
        latestError: '',
      };
    case FETCH_LATEST_UPLOADS_COMPLETED:
      return {
        ...state,
        searching: '',
        isFetchingLatest: false,
        hasLatestError: false,
        latestUploadsData: payload,
      };
    case FETCH_LATEST_UPLOADS_FAILED:
      return {
        ...state,
        isFetchingLatest: false,
        hasLatestError: true,
        latestError: payload.error,
        bulkUploadData: null,
      };
    case BULK_UPLOAD_DOCUMENTS:
      return {
        ...state,
        uploadError: '',
        isRequestingUpload: true,
        hasUploadError: false,
      };
    case BULK_UPLOAD_DOCUMENTS_COMPLETED:
      return {
        ...state,
        isRequestingUpload: false,
        hasUploadError: false,
      };
    case BULK_UPLOAD_DOCUMENTS_FAILED:
      return {
        ...state,
        isRequestingUpload: false,
        hasUploadError: true,
        uploadError: payload.message,
      };
    default:
      return state;
  }
};
