import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';
import axios from 'axios';

import {
  EDIT_CITY_RECORD_COMPLETED,
  ADD_CITY_RECORD_COMPLETED,
} from '../../record';
import UseAbortApiCall from '../../../Common/Hooks/use-abort';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  cityIds: [],
  cityCount: 0,
  search: '',
  cityRecord: {},
  headerVisible: false,
  resetPage: false,
  isFetchingLatLong: false,
  cityTabVal: 'WH',
};

const FETCH_CITIES_DETAILS = 'FETCH_CITIES_DETAILS';
const FETCH_CITIES_DETAILS_COMPLETED = 'FETCH_CITIES_DETAILS_COMPLETED';
const FETCH_CITIES_DETAILS_FAILED = 'FETCH_CITIES_DETAILS_FAILED';

const FETCH_CITY_LAT_LONG = 'FETCH_CITY_LAT_LONG';
const FETCH_CITY_LAT_LONG_COMPLETED = 'FETCH_CITY_LAT_LONG_COMPLETED';
const FETCH_CITY_LAT_LONG_FAILED = 'FETCH_CITY_LAT_LONG_FAILED';

const FETCH_WAREHOUSE_DETAILS = 'FETCH_WAREHOUSE_DETAILS';
const FETCH_WAREHOUSE_DETAILS_COMPLETED = 'FETCH_WAREHOUSE_DETAILS_COMPLETED';
const FETCH_WAREHOUSE_DETAILS_FAILED = 'FETCH_WAREHOUSE_DETAILS_FAILED';

const TOGGLE_ADD_CITY_HEADER = 'TOGGLE_ADD_CITY_HEADER ';

const RESET_PAGE = 'RESET_PAGE';
const CITY_TAB_VALUE = 'CITY_TAB_VALUE';

export const toggleAddCityHeader = () => {
  return (dispatch) => {
    dispatch({type: TOGGLE_ADD_CITY_HEADER});
  };
};

export const cityTabValue = (data = '') => {
  return (dispatch) => {
    dispatch({
      type: CITY_TAB_VALUE,
      payload: data,
    });
  };
};

export const fetchCityLatLong = (search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CITY_LAT_LONG});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchCityLatLongURL(search),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: FETCH_CITY_LAT_LONG_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CITY_LAT_LONG_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CITY_LAT_LONG_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchCityDetails = (page, search, type, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDoList');
    try {
      dispatch({type: FETCH_CITIES_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: Records.fetchCityDetailsURL(page, search, type),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_CITIES_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CITIES_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return {
          error: true,
        };
      }
      dispatch({
        type: FETCH_CITIES_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setCityIds = (state, payload = {}) => {
  const {city_list} = payload;
  if (state?.cityIds?.length <= 30) {
    const updatedState = [...state.cityIds, ...city_list];
    return [...updatedState];
  } else return state.cityIds;
};

// const setInitalVehiclesData = (state, payload = {}) => {
//   const {vehicles} = payload;
//   console.log('vehicle tables data', vehicles);
//   if (vehicles) {
//     const oldVehicles = {...state};
//     const newVehicles = {...vehicles};
//     const updatedState = merge(oldVehicles, newVehicles);
//     return {...updatedState};
//   } else return state;
// };

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_CITY_LAT_LONG:
      return {
        ...state,
        isFetchingLatLong: true,
      };
    case FETCH_CITY_LAT_LONG_COMPLETED:
      return {
        ...state,
        isFetchingLatLong: false,
      };
    case FETCH_CITY_LAT_LONG_FAILED:
      return {
        ...state,
        isFetchingLatLong: false,
        hasError: false,
        error: '',
      };
    case FETCH_CITIES_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_CITIES_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        cityIds: payload?.city_list,
        cityCount: payload?.total,
        cityRecord: payload?.cities_record,
        total: payload?.total,
        search: payload?.query_string,
      };
    case FETCH_CITIES_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        cityIds: [],
        cityRecord: {},
      };
    case FETCH_WAREHOUSE_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_WAREHOUSE_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        cityIds: payload?.city_list,
        cityCount: payload?.total,
        cityRecord: payload?.cities_record,
        total: payload?.total,
        search: payload?.query_string,
      };
    case FETCH_WAREHOUSE_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        cityIds: [],
        cityRecord: {},
      };
    case TOGGLE_ADD_CITY_HEADER:
      return {...state, headerVisible: !state.headerVisible};
    case EDIT_CITY_RECORD_COMPLETED:
      return {
        ...state,
        cityRecord: {...state.cityRecord, ...payload?.cities_record},
      };
    case ADD_CITY_RECORD_COMPLETED:
      return {
        ...state,
        cityRecord: {...state.cityRecord, ...payload?.cities_record},
        cityIds: payload.city_list,
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    case CITY_TAB_VALUE:
      return {
        ...state,
        cityTabVal: payload,
      };
    default:
      return state;
  }
};
