import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Bookings} from '../../../helpers/Urls';
import {fetchAvailableVehicleReportURL} from '../../../helpers/Urls/bookings';

const INITIAL_STATE = {
  isFetching: false,
  availableVehicles: {},
  waitingForUnload: {},
  total: {},
  search: '',
  isDownloading: false,
};

export const FETCH_AVAILABLE_VEHICLES_REPORT =
  'FETCH_AVAILABLE_VEHICLES_REPORT';
export const FETCH_AVAILABLE_VEHICLES_REPORT_COMPLETED =
  'FETCH_AVAILABLE_VEHICLES_REPORT_COMPLETED';
export const FETCH_AVAILABLE_VEHICLES_REPORT_FAILED =
  'FETCH_AVAILABLE_VEHICLES_REPORT_FAILED';

export const fetchAvailableVehiclesReport = (search = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_AVAILABLE_VEHICLES_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchAvailableVehicleReportURL(search),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_AVAILABLE_VEHICLES_REPORT_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_AVAILABLE_VEHICLES_REPORT_FAILED,
          payload: {error: message, search},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_AVAILABLE_VEHICLES_REPORT_FAILED,
        payload: {error: err.message, search},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_AVAILABLE_VEHICLES_REPORT:
      return {...state, isFetching: true};
    case FETCH_AVAILABLE_VEHICLES_REPORT_COMPLETED:
      return {
        ...state,
        isFetching: false,
        availableVehicles: payload.available_vehicles,
        waitingForUnload: payload.waiting_for_unload,
        total: payload.total,
        search: payload.search,
      };
    case FETCH_AVAILABLE_VEHICLES_REPORT_FAILED:
      return {...state, isFetching: false, search: payload.search};
    default:
      return state;
  }
};
