import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE, RECORD_TYPE} from '../../../constants';
import * as _ from 'lodash';
import {Records} from '../../../helpers/Urls';

import {
  EDIT_DRIVER_PHONE_DETAILS_COMPLETED,
  ADD_CITY_RECORD_COMPLETED,
  ADD_ROUTE_TAT_COMPLETED,
} from '../../record';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  vehicleDetails: {},
  document_type: {},
  routeTats: {},
  cityRecords: {},
  requesting: false,
  isUploading: false,
  isBillingDataDownloading: false,
  isPaymentDataDownloading: false,
};

export const SEARCH_IN_RECORD_TRUE = 'SEARCH_IN_RECORD_TRUE';
export const SEARCH_IN_RECORD_FALSE = 'SEARCH_IN_RECORD_FALSE';
export const SEARCH_VENDOR_TRUE = 'SEARCH_VENDOR_TRUE';
export const SEARCH_VENDOR_FALSE = 'SEARCH_VENDOR_FALSE';

export const FETCH_RECORDS = 'FETCH_RECORDS';
export const FETCH_RECORDS_COMPLETED = 'FETCH_RECORDS_COMPLETED';
export const FETCH_RECORDS_FAILED = 'FETCH_RECORDS_FAILED';

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_COMPLETED = 'UPLOAD_DOCUMENT_COMPLETED';
export const UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT_FAILED';

export const DOWNLOAD_BILLING_ENTITY = 'DOWNLOAD_BILLING_ENTITY';
export const DOWNLOAD_BILLING_ENTITY_COMPLETED =
  'DOWNLOAD_BILLING_ENTITY_COMPLETED';
export const DOWNLOAD_BILLING_ENTITY_FAILED = 'DOWNLOAD_BILLING_ENTITY_FAILED';

export const DOWNLOAD_PAYMENT_ENTITY = 'DOWNLOAD_PAYMENT_ENTITY';
export const DOWNLOAD_PAYMENT_ENTITY_COMPLETED =
  'DOWNLOAD_PAYMENT_ENTITY_COMPLETED';
export const DOWNLOAD_PAYMENT_ENTITY_FAILED = 'DOWNLOAD_PAYMENT_ENTITY_FAILED';

const SEND_VEHICLE_DOCUMENT = 'SEND_VEHICLE_DOCUMENT';
const SEND_VEHICLE_DOCUMENT_COMPLETED = 'SEND_VEHICLE_DOCUMENT_COMPLETED';
const SEND_VEHICLE_DOCUMENT_FAILED = 'SEND_VEHICLE_DOCUMENT_FAILED';

export const sendRecordDocuments = (url) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_VEHICLE_DOCUMENT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.sendRecordDocumentsURL(url),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: SEND_VEHICLE_DOCUMENT_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: SEND_VEHICLE_DOCUMENT_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: SEND_VEHICLE_DOCUMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadBillingEntityRecord = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_BILLING_ENTITY});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.downloadBillingEntityRecordURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_BILLING_ENTITY_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_BILLING_ENTITY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_BILLING_ENTITY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadPaymentEntityRecord = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_PAYMENT_ENTITY});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.downloadPaymentEntityRecordURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_PAYMENT_ENTITY_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_PAYMENT_ENTITY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_PAYMENT_ENTITY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const uploadDocument = (requestData = {}, url = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_DOCUMENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.uploadDocumentURL(url),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: UPLOAD_DOCUMENT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: UPLOAD_DOCUMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPLOAD_DOCUMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchRecords = (records) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_RECORDS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchRecordsUrl(records),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_RECORDS_COMPLETED,
          payload: {...data, records},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_RECORDS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_RECORDS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
const onRecordsFetchedSuccessfully = (state, payload) => {
  const {
    records = '',
    vehicle_detail = {},
    document_type = {},
    driver_details = {},
    route_tats = {},
    city_records = {},
    can_upload_document = true,
  } = payload;
  if (records === RECORD_TYPE.VEHICLES) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      vehicleDetails: vehicle_detail,
      searching: '',
      document_type,
      can_upload_document,
    };
  } else if (records === RECORD_TYPE.DRIVERS) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      searching: '',
      driverDetails: driver_details,
    };
  } else if (records === RECORD_TYPE.ROUTE_TAT) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      searching: '',
      routeTats: route_tats,
    };
  } else if (records === RECORD_TYPE.CITIES) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      searching: '',
      cityRecords: city_records,
    };
  }
};

export const searchInput = (searchInput = '') => (dispatch) => {
  if (!searchInput) {
    dispatch({
      type: SEARCH_IN_RECORD_FALSE,
    });
    return;
  }
  dispatch({
    type: SEARCH_IN_RECORD_TRUE,
    payload: searchInput,
  });
};

export const searchVendor = (searchVendorId = '') => (dispatch) => {
  if (!searchVendorId) {
    dispatch({
      type: SEARCH_VENDOR_FALSE,
    });
    return;
  }
  dispatch({
    type: SEARCH_VENDOR_TRUE,
    payload: searchVendorId,
  });
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_RECORDS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_RECORDS_COMPLETED:
      return onRecordsFetchedSuccessfully(state, payload);
    case FETCH_RECORDS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.message,
      };
    case SEARCH_IN_RECORD_FALSE:
      return {
        ...state,
        searching: '',
        // filteredTrips: null,
      };
    case SEARCH_IN_RECORD_TRUE:
      return {
        ...state,
        searching: payload,
        // filteredTrips: payload,
      };
    case SEARCH_VENDOR_FALSE:
      return {
        ...state,
        searchVendorId: '',
        // filteredTrips: payload,
      };
    case SEARCH_VENDOR_TRUE:
      return {
        ...state,
        searchVendorId: payload,
        // filteredTrips: payload,
      };
    case UPLOAD_DOCUMENT:
      return {
        ...state,
        isUploading: true,
      };
    case UPLOAD_DOCUMENT_COMPLETED:
      return {
        ...state,
        isUploading: false,
      };
    case UPLOAD_DOCUMENT_FAILED:
      return {
        ...state,
        isUploading: false,
      };

    case DOWNLOAD_BILLING_ENTITY:
      return {
        ...state,
        isBillingDataDownloading: true,
      };
    case DOWNLOAD_BILLING_ENTITY_COMPLETED:
      return {
        ...state,
        isBillingDataDownloading: false,
      };
    case DOWNLOAD_BILLING_ENTITY_FAILED:
      return {
        ...state,
        isBillingDataDownloading: false,
      };

    case DOWNLOAD_PAYMENT_ENTITY:
      return {
        ...state,
        isPaymentDataDownloading: true,
      };
    case DOWNLOAD_PAYMENT_ENTITY_COMPLETED:
      return {
        ...state,
        isPaymentDataDownloading: false,
      };
    case DOWNLOAD_PAYMENT_ENTITY_FAILED:
      return {
        ...state,
        isPaymentDataDownloading: false,
      };

    case ADD_CITY_RECORD_COMPLETED:
      return {
        ...state,
        cityRecords: {...state.cityRecords, ...payload.city_records},
      };
    case ADD_ROUTE_TAT_COMPLETED:
      return {
        ...state,
        routeTats: {...state.routeTats, ...payload.route_tats},
      };
    case EDIT_DRIVER_PHONE_DETAILS_COMPLETED: {
      return {
        ...state,
        driverDetails: {...state.driverDetails, ...payload.driver_details},
      };
    }
    case SEND_VEHICLE_DOCUMENT:
      return {...state, requesting: true};
    case SEND_VEHICLE_DOCUMENT_COMPLETED:
    case SEND_VEHICLE_DOCUMENT_FAILED:
      return {...state, requesting: false};
    default:
      return state;
  }
};
