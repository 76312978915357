import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Auth} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  mailData: [],
  alertMail: {},
  isRequesting: false,
};

const FETCH_ALERT_MAILS = 'FETCH_ALERT_MAILS';
const FETCH_ALERT_MAILS_COMPLETED = 'FETCH_ALERT_MAILS_COMPLETED';
const FETCH_ALERT_MAILS_FAILED = 'FETCH_ALERT_MAILS_FAILED';

export const SUBSCRIBE_ALERT_MAIL = 'SUBSCRIBE_ALERT_MAIL';
export const SUBSCRIBE_ALERT_MAIL_COMPLETED = 'SUBSCRIBE_ALERT_MAIL_COMPLETED';
export const SUBSCRIBE_ALERT_MAIL_FAILED = 'SUBSCRIBE_ALERT_MAIL_FAILED';

export const fetchAlertMails = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ALERT_MAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.fetchAlertMailsURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_ALERT_MAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_ALERT_MAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ALERT_MAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const subscribeAlertMail = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SUBSCRIBE_ALERT_MAIL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Auth.subscribeAlertMailURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: SUBSCRIBE_ALERT_MAIL_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SUBSCRIBE_ALERT_MAIL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SUBSCRIBE_ALERT_MAIL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_ALERT_MAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_ALERT_MAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        mailData: payload,
      };
    case FETCH_ALERT_MAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case SUBSCRIBE_ALERT_MAIL:
      return {...state, isRequesting: true};
    case SUBSCRIBE_ALERT_MAIL_COMPLETED: {
      return {...state, isRequesting: false, alertMail: payload};
    }
    case SUBSCRIBE_ALERT_MAIL_FAILED:
      return {...state, isRequesting: false};
    default:
      return state;
  }
};
