import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Bookings} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  savingNewBid: false,
  bids: {},
  bidsIds: [],
  teamBids: {},
  teamBidsIds: [],
  historicalCostData: {},
  historicalCostDataIDs: [],
  totalBidsCount: 0,
};

export const FETCH_BOOKING_BIDS = 'FETCH_BOOKING_BIDS';
export const FETCH_BOOKING_BIDS_COMPLETED = 'FETCH_BOOKING_BIDS_COMPLETED';
export const FETCH_BOOKING_BIDS_FAILED = 'FETCH_BOOKING_BIDS_FAILED';

export const ADD_BID_DETAILS = 'ADD_BID_DETAILS';
export const ADD_BID_DETAILS_COMPLETED = 'ADD_BID_DETAILS_COMPLETED';
export const ADD_BID_DETAILS_FAILED = 'ADD_BID_DETAILS_FAILED';

export const UPDATE_BIDS = 'UPDATE_BIDS';
export const UPDATE_BIDS_COMPLETED = 'UPDATE_BIDS_COMPLETED';
export const UPDATE_BIDS_FAILED = 'UPDATE_BIDS_FAILED';

export const fetchBookingBids = (bookingId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_BOOKING_BIDS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchBookingBidsURL(bookingId),
      });
      console.log('response --FETCH_BOOKING_BIDS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_BOOKING_BIDS_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_BOOKING_BIDS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BOOKING_BIDS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addBidDetails = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_BID_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.addBidDetailsURL(),
        data: requestData,
      });
      console.log('response --ADD_BID_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: ADD_BID_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: ADD_BID_DETAILS_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: ADD_BID_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateBid = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_BIDS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.updateBidURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: UPDATE_BIDS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: UPDATE_BIDS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_BIDS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_BOOKING_BIDS:
      return {...state, isFetching: true};
    case FETCH_BOOKING_BIDS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        bids: payload.bids,
        bidsIds: payload.bids_ids,
        teamBids: payload.team_bids,
        totalBidsCount: payload.bids_count,
        teamBidsIds: payload.team_bids_ids,
        historicalCostData: payload.historical_cost_data,
        historicalCostDataIDs: payload.historical_cost_data_ids,
      };
    case FETCH_BOOKING_BIDS_FAILED:
      return {...state, isFetching: false};
    case ADD_BID_DETAILS:
      return {...state, savingNewBid: true};
    case ADD_BID_DETAILS_COMPLETED:
      return {...state, savingNewBid: false};
    case ADD_BID_DETAILS_FAILED:
      return {...state, savingNewBid: false};
    default:
      return state;
  }
};
