import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records, Vendors} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  addingDetails: false,
  error: '',
  isFetchingCustomerDetails: false,
  getCustomerDetailsData: {},
};

const ADD_CUSTOMER = 'ADD_CUSTOMER';
const ADD_CUSTOMER_COMPLETED = 'ADD_CUSTOMER_COMPLETED';
const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';

const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
const EDIT_CUSTOMER_COMPLETED = 'EDIT_CUSTOMER_COMPLETED';
const EDIT_CUSTOMER_FAILED = 'EDIT_CUSTOMER_FAILED';

const ADD_CUSTOMER_DETAILS = 'ADD_CUSTOMER_DETAILS';
const ADD_CUSTOMER_DETAILS_COMPLETED = 'ADD_CUSTOMER_DETAILS_COMPLETED';
const ADD_CUSTOMER_DETAILS_FAILED = 'ADD_CUSTOMER_DETAILS_FAILED';

const EDIT_CUSTOMER_DETAILS = 'EDIT_CUSTOMER_DETAILS';
const EDIT_CUSTOMER_DETAILS_COMPLETED = 'EDIT_CUSTOMER_DETAILS_COMPLETED';
const EDIT_CUSTOMER_DETAILS_FAILED = 'EDIT_CUSTOMER_DETAILS_FAILED';

const GENERATE_NEW_OTP = 'GENERATE_NEW_OTP';
const GENERATE_NEW_OTP_COMPLETED = 'GENERATE_NEW_OTP_COMPLETED';
const GENERATE_NEW_OTP_FAILED = 'GENERATE_NEW_OTP_FAILED';

const CUSTOMER_OPERATIONAL_DATA = 'CUSTOMER_OPERATIONAL_DATA';
const CUSTOMER_FINANCIAL_DATA = 'CUSTOMER_FINANCIAL_DATA';

const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
const GET_CUSTOMER_DETAILS_COMPLETED = 'GET_CUSTOMER_DETAILS_COMPLETED';
const GET_CUSTOMER_DETAILS_FAILED = 'GET_CUSTOMER_DETAILS_FAILED';

export const customerOperationalData = (customer_operational_data) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_OPERATIONAL_DATA,
      payload: {customer_operational_data},
    });
  };
};

export const customerFinancialData = (customer_financial_data) => {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_FINANCIAL_DATA,
      payload: {customer_financial_data},
    });
  };
};

export const checkAvailability = (data) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.checkAvailabilityURL(),
        data,
      });
    } catch (err) {}
    return response;
  };
};

export const generateNewOtp = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GENERATE_NEW_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.generateNewOtpURL(),
        data: requestData,
      });
      console.log('response --ADD_VENDOR_COMPLETED--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: GENERATE_NEW_OTP_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: GENERATE_NEW_OTP_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: GENERATE_NEW_OTP_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const getCustomerDetails = (customerId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_CUSTOMER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.getCustomerDetailsURL(customerId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_CUSTOMER_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_CUSTOMER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_CUSTOMER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addCustomerDetails = (customerId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_CUSTOMER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addCustomerDetailsURL(customerId),
        data: requestData,
      });
      console.log('response --ADD_CUSTOMER_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: ADD_CUSTOMER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_CUSTOMER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_CUSTOMER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addCustomer = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addCustomerURL(),
        data: requestData,
      });
      console.log('response --ADD_CUSTOMER_COMPLETED--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: ADD_CUSTOMER_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: ADD_CUSTOMER_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: ADD_CUSTOMER_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const editCustomer = (customerId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editCustomerURL(customerId),
        data: requestData,
      });
      console.log('response --EDIT_CUSTOMER--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: EDIT_CUSTOMER_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: EDIT_CUSTOMER_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: EDIT_CUSTOMER_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const editCustomerDetails = (customerId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_CUSTOMER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editCustomerDetailsURL(customerId),
        data: requestData,
      });
      console.log('response --EDIT_CUSTOMER--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: EDIT_CUSTOMER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_CUSTOMER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_CUSTOMER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case GET_CUSTOMER_DETAILS:
      return {
        ...state,
        isFetchingCustomerDetails: true,
        hasError: false,
        error: '',
      };
    case GET_CUSTOMER_DETAILS_COMPLETED:
      return {
        ...state,
        isFetchingCustomerDetails: false,
        hasError: false,
        getCustomerDetailsData: payload.data,
      };
    case GET_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        isFetchingCustomerDetails: false,
        hasError: true,
        error: payload.error,
      };
    case ADD_CUSTOMER:
    case EDIT_CUSTOMER:
    case ADD_CUSTOMER_DETAILS:
    case EDIT_CUSTOMER_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case ADD_CUSTOMER_COMPLETED:
    case EDIT_CUSTOMER_COMPLETED:
    case ADD_CUSTOMER_DETAILS_COMPLETED:
    case EDIT_CUSTOMER_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
      };
    case ADD_CUSTOMER_FAILED:
    case EDIT_CUSTOMER_FAILED:
    case ADD_CUSTOMER_DETAILS_FAILED:
    case EDIT_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case CUSTOMER_OPERATIONAL_DATA:
      return {
        customer_operational_data: payload.customer_operational_data,
      };
    case CUSTOMER_FINANCIAL_DATA:
      return {
        customer_financial_data: payload.customer_financial_data,
      };
    // case ADD_CUSTOMER_DETAILS:
    //   return {...state, addingDetails: true};
    // case ADD_CUSTOMER_DETAILS_COMPLETED:
    // case ADD_CUSTOMER_DETAILS_FAILED:
    //   return {...state, addingDetails: false};
    default:
      return state;
  }
};
