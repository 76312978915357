import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {LR} from '../../helpers/Urls';

export const FETCH_LORRY_RECEIPT_BY_ID = 'FETCH_LORRY_RECEIPT_BY_ID';
export const FETCH_LORRY_RECEIPT_BY_ID_COMPLETED =
  'FETCH_LORRY_RECEIPT_BY_ID_COMPLETED';
export const FETCH_LORRY_RECEIPT_BY_ID_FAILED =
  'FETCH_LORRY_RECEIPT_BY_ID_FAILED';

export const FETCH_LORRY_RECEIPT_DATA = 'FETCH_LORRY_RECEIPT_DATA';
export const FETCH_LORRY_RECEIPT_DATA_COMPLETED =
  'FETCH_LORRY_RECEIPT_DATA_COMPLETED';
export const FETCH_LORRY_RECEIPT_DATA_FAILED =
  'FETCH_LORRY_RECEIPT_DATA_FAILED';

export const CREATE_LORRY_RECEIPT = 'CREATE_LORRY_RECEIPT';
export const CREATE_LORRY_RECEIPT_COMPLETED = 'CREATE_LORRY_RECEIPT_COMPLETED';
export const CREATE_LORRY_RECEIPT_FAILED = 'CREATE_LORRY_RECEIPT_FAILED';

export const PRINT_LORRY_RECEIPT = 'PRINT_LORRY_RECEIPT';
export const PRINT_LORRY_RECEIPT_COMPLETED = 'PRINT_LORRY_RECEIPT_COMPLETED';
export const PRINT_LORRY_RECEIPT_FAILED = 'PRINT_LORRY_RECEIPT_FAILED';

export const createOrUpdateLorryReceipt = (
  tripId,
  lrId,
  requestData,
  update,
) => {
  console.log('update ----', update);
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_LORRY_RECEIPT});
      response = await doRequest({
        method: update ? REQUEST_TYPE.PUT : REQUEST_TYPE.POST,
        url: update
          ? LR.fetchLorryReceiptByIdURL(tripId, lrId)
          : LR.fetchLorryReceiptDataURL(tripId),
        data: requestData,
      });
      console.log('response --FETCH_LORRY_RECEIPT_DATA--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CREATE_LORRY_RECEIPT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_LORRY_RECEIPT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_LORRY_RECEIPT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchLorryReceiptById = (tripId, lrId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_LORRY_RECEIPT_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: LR.fetchLorryReceiptByIdURL(tripId, lrId),
      });
      console.log('response --FETCH_LORRY_RECEIPT_BY_ID--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_LORRY_RECEIPT_BY_ID_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_LORRY_RECEIPT_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_LORRY_RECEIPT_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchLorryReceiptData = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_LORRY_RECEIPT_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: LR.fetchLorryReceiptDataURL(tripId),
      });
      console.log('response --FETCH_LORRY_RECEIPT_DATA--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_LORRY_RECEIPT_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_LORRY_RECEIPT_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_LORRY_RECEIPT_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const printLorryReceipt = (tripId, lrId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: PRINT_LORRY_RECEIPT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: LR.printLorryReceiptURL(tripId, lrId),
      });
      // console.log('response --PRINT_LORRY_RECEIPT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: PRINT_LORRY_RECEIPT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: PRINT_LORRY_RECEIPT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: PRINT_LORRY_RECEIPT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.lorry_reciepts) {
        return {...state, ...payload.lorry_reciepts};
      }
      return state;
    }
  }
};
