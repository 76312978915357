import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Vendors} from '../../../helpers/Urls';
import {merge} from 'lodash';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  addingDetails: false,
  error: '',
  checkingIFSC: false,
  vendor_operational_data: false,
  vendor_financial_data: false,
  vendorNewData: [],
};

const ADD_VENDOR = 'ADD_VENDOR';
const ADD_VENDOR_COMPLETED = 'ADD_VENDOR_COMPLETED';
const ADD_VENDOR_FAILED = 'ADD_VENDOR_FAILED';

const EDIT_VENDOR = 'EDIT_VENDOR';
const EDIT_VENDOR_COMPLETED = 'EDIT_VENDOR_COMPLETED';
const EDIT_VENDOR_FAILED = 'EDIT_VENDOR_FAILED';

const FETCH_VENDOR_BY_ID = 'FETCH_VENDOR_BY_ID';
const FETCH_VENDOR_BY_ID_COMPLETED = 'FETCH_VENDOR_BY_ID_COMPLETED';
const FETCH_VENDOR_BY_ID_FAILED = 'FETCH_VENDOR_BY_ID_FAILED';

const ADD_VENDOR_DETAILS = 'ADD_VENDOR_DETAILS';
const ADD_VENDOR_DETAILS_COMPLETED = 'ADD_VENDOR_DETAILS_COMPLETED';
const ADD_VENDOR_DETAILS_FAILED = 'ADD_VENDOR_DETAILS_FAILED';

const EDIT_VENDOR_DETAILS = 'EDIT_VENDOR_DETAILS';
const EDIT_VENDOR_DETAILS_COMPLETED = 'EDIT_VENDOR_DETAILS_COMPLETED';
const EDIT_VENDOR_DETAILS_FAILED = 'EDIT_VENDOR_DETAILS_FAILED';

const FETCH_CUSTOMER_INITIAL_DATA = 'FETCH_CUSTOMER_INITIAL_DATA';
const FETCH_CUSTOMER_INITIAL_DATA_COMPLETED =
  'FETCH_CUSTOMER_INITIAL_DATA_COMPLETED';

const FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE';
const FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_COMPLETED =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_COMPLETED';

const GENERATE_NEW_OTP = 'GENERATE_NEW_OTP';
const GENERATE_NEW_OTP_COMPLETED = 'GENERATE_NEW_OTP_COMPLETED';
const GENERATE_NEW_OTP_FAILED = 'GENERATE_NEW_OTP_FAILED';

const CHECK_IFSC = 'CHECK_IFSC';
const CHECK_IFSC_COMPLETED = 'CHECK_IFSC_COMPLETED';
const CHECK_IFSC_FAILED = 'CHECK_IFSC_FAILED';

const VENDOR_OPERATIONAL_DATA = 'VENDOR_OPERATIONAL_DATA';
const VENDOR_FINANCIAL_DATA = 'VENDOR_FINANCIAL_DATA';

const VENDOR_GST_DETAILS = 'VENDOR_GST_DETAILS';
const VENDOR_GST_DETAILS_COMPLETED = 'VENDOR_GST_DETAILS_COMPLETED';
const VENDOR_GST_DETAILS_FAILED = 'VENDOR_GST_DETAILS_FAILED';

export const vendorOperationalData = (vendor_operational_data) => {
  return (dispatch) => {
    dispatch({
      type: VENDOR_OPERATIONAL_DATA,
      payload: {vendor_operational_data},
    });
  };
};

export const vendorFinancialData = (vendor_financial_data) => {
  return (dispatch) => {
    dispatch({type: VENDOR_FINANCIAL_DATA, payload: {vendor_financial_data}});
  };
};

export const suggestName = (phrase) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.getSuggestionURL(),
        data: {phrase},
      });
    } catch (err) {}
    return response;
  };
};

export const suggestNickName = (phrase) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.getNickNameSuggestionURL(),
        data: {phrase},
      });
    } catch (err) {}
    return response;
  };
};

export const sendAllData = (data) => {
  return async (dispatch) => {
    let response = await doRequest({
      method: REQUEST_TYPE.POST,
      url: 'vendor/add',
      data: data,
    });
  };
};

export const fetchVendorInitialDataNew = () => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: 'vendor/add',
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: 'FETCH_VENDOR_INITIAL_DATA_COMPLETED_NEW',
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FETCH_CUSTOMER_INITIAL_DATA_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FETCH_CUSTOMER_INITIAL_DATA_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchVendorInitialData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_INITIAL_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Vendors.fetchVendorInitialDataURL(),
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: 'FETCH_VENDOR_INITIAL_DATA_COMPLETED',
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const fetchVendorInitialDataCSCState = (filter) => {
  const {flag} = filter;
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url:
          flag === 'Country'
            ? Vendors.fetchVendorInitialDataCSCCountryURL(filter)
            : Vendors.fetchVendorInitialDataCSCStateURL(filter),
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: 'FETCH_VENDOR_INITIAL_DATA_CSC_STATE_COMPLETED',
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const fetchVendorInitialDataCSCCity = (filter) => {
  const {flag} = filter;
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url:
          flag === 'Country'
            ? Vendors.fetchVendorInitialDataCSCCountryURL(filter)
            : Vendors.fetchVendorInitialDataCSCStateURL(filter),
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        console.log('PAYLOAD', data);
        dispatch({
          type: 'FETCH_VENDORS_INITIAL_DATA_CSC_CITY_COMPLETED',
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const addVendorDetails = (vendorId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_VENDOR_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.addVendorDetailsURL(vendorId),
        data: requestData,
      });
      console.log('response --ADD_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: ADD_VENDOR_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: ADD_VENDOR_DETAILS_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: ADD_VENDOR_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addVendor = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_VENDOR});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.addVendorURL(),
        data: requestData,
      });
      console.log('response --ADD_VENDOR_COMPLETED--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: ADD_VENDOR_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: ADD_VENDOR_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: ADD_VENDOR_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const generateNewOtp = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GENERATE_NEW_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.generateNewOtpURL(),
        data: requestData,
      });
      console.log('response --ADD_VENDOR_COMPLETED--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: GENERATE_NEW_OTP_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: GENERATE_NEW_OTP_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: GENERATE_NEW_OTP_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const editVendor = (vendorId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_VENDOR});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.editVendorURL(vendorId),
        data: requestData,
      });
      console.log('response --EDIT_VENDOR--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: EDIT_VENDOR_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: EDIT_VENDOR_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: EDIT_VENDOR_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const editVendorDetails = (vendorId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_VENDOR_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.editVendorDetailsURL(vendorId),
        data: requestData,
      });
      console.log('response --EDIT_VENDOR--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: EDIT_VENDOR_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: EDIT_VENDOR_DETAILS_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: EDIT_VENDOR_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const vendorGSTDetails = (gstValue) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: VENDOR_GST_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `vendor/gst-details/${gstValue}`,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: VENDOR_GST_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: VENDOR_GST_DETAILS_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: VENDOR_GST_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const checkIFSC = (code) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHECK_IFSC});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Vendors.checkIFSCCodeURL(code),
      });
      // console.log('response --CHECK_IFSC_COMPLETED--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: CHECK_IFSC_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: CHECK_IFSC_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({
        type: CHECK_IFSC_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const showBlackListData = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: 'vendor/partner-blacklist/' + id,
      });
    } catch (err) {}
    return response;
  };
};

export const sendRejectData = (id, data) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'vendor/partner-blacklist/' + id,
        data: data,
      });
    } catch (err) {}
    return response;
  };
};

export const fetchVendorById = (vendor_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VENDOR_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Vendors.editVendorURL(vendor_id),
      });
      console.log('response --FETCH_VENDOR_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        console.log('response --', response.data);
        dispatch({
          type: FETCH_VENDOR_BY_ID_COMPLETED,
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VENDOR_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VENDOR_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setInitialVendorData = (state, payload = {}) => {
  const {vendors} = payload;

  if (vendors) {
    const oldVendors = {...state};
    const newVendors = {...vendors};
    const updatedState = merge(oldVendors, newVendors);
    return {...updatedState};
  } else return state;
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case ADD_VENDOR:
    case EDIT_VENDOR:
    case ADD_VENDOR_DETAILS:
    case EDIT_VENDOR_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case ADD_VENDOR_COMPLETED:
    case EDIT_VENDOR_COMPLETED:
    case ADD_VENDOR_DETAILS_COMPLETED:
    case EDIT_VENDOR_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
      };
    case ADD_VENDOR_FAILED:
    case EDIT_VENDOR_FAILED:
    case ADD_VENDOR_DETAILS_FAILED:
    case EDIT_VENDOR_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case CHECK_IFSC:
      return {...state, checkingIFSC: true};
    case CHECK_IFSC_COMPLETED:
    case CHECK_IFSC_FAILED:
      return {...state, checkingIFSC: false};
    case 'FETCH_VENDOR_INITIAL_DATA_COMPLETED_NEW':
      return {
        ...state,
        vendorNewData: payload,
      };
    case VENDOR_OPERATIONAL_DATA:
      return {
        ...state,
        vendor_operational_data: payload.vendor_operational_data,
      };
    case VENDOR_FINANCIAL_DATA:
      return {
        ...state,
        vendor_financial_data: payload.vendor_financial_data,
      };
    case 'FETCH_VENDOR_INITIAL_DATA_COMPLETED':
      return {
        ...state,

        vendorDropAllData: payload,
      };

    case 'FETCH_VENDOR_INITIAL_DATA_CSC_STATE_COMPLETED':
      return {
        ...state,

        vendorInitialDataCSCState: payload,
      };

    case 'FETCH_VENDORS_INITIAL_DATA_CSC_CITY_COMPLETED':
      console.log('ascfasfdsfvds', payload);
      return {
        ...state,
        VendorsInitialDataCSCCity: payload,
      };
    // case ADD_VENDOR_DETAILS:
    //   return {...state, addingDetails: true};
    // case ADD_VENDOR_DETAILS_COMPLETED:
    // case ADD_VENDOR_DETAILS_FAILED:
    //   return {...state, addingDetails: false};
    default:
      return state;
  }
};
