import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {OwnVehicles} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  approvalRecords: {},
  approvalRecordIds: [],
  resetPage: false,
  isRequesting: false,
};

const FETCH_APPROVE_DIESEL = 'FETCH_APPROVE_DIESEL';
const FETCH_APPROVE_DIESEL_COMPLETED = 'FETCH_APPROVE_DIESEL_COMPLETED';
const FETCH_APPROVE_DIESEL_FAILED = 'FETCH_APPROVE_DIESEL_FAILED';

export const APPROVE_DIESEL = 'APPROVE_DIESEL';
export const APPROVE_DIESEL_COMPLETED = 'APPROVE_DIESEL_COMPLETED';
export const APPROVE_DIESEL_FAILED = 'APPROVE_DIESEL_FAILED';

const RESET_PAGE = 'RESET_PAGE';

export const fetchApproveDiesel = (search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_APPROVE_DIESEL});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: OwnVehicles.fetchApproveDieselURL(search),
      });
      // console.log('response --FETCH_APPROVE_DIESEL_COMPLETED--', response);
      const {data, status} = response || {};

      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_APPROVE_DIESEL_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_APPROVE_DIESEL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_APPROVE_DIESEL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const approveDiesel = (requestData, search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPROVE_DIESEL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: OwnVehicles.approveDieselURL(search),
        data: requestData,
      });
      console.log('response --APPROVE_DIESEL--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: APPROVE_DIESEL_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: APPROVE_DIESEL_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: APPROVE_DIESEL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_APPROVE_DIESEL:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_APPROVE_DIESEL_COMPLETED:
    case APPROVE_DIESEL_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        approvalRecords: payload.approval_records,
        approvalRecordIds: Object.keys(payload?.approval_records),
        search: payload.query_string,
      };
    case FETCH_APPROVE_DIESEL_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        approvalRecordIds: [],
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    case APPROVE_DIESEL:
      return {...state, isRequesting: true};
    case APPROVE_DIESEL_FAILED:
      return {...state, isRequesting: false};
    default:
      return state;
  }
};
