import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Bookings} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  availableVehicles: [],
  filters: {},
  search: '',
  isDownloading: false,
};

export const FETCH_AVAILABLE_VEHICLES = 'FETCH_AVAILABLE_VEHICLES';
export const FETCH_AVAILABLE_VEHICLES_COMPLETED =
  'FETCH_AVAILABLE_VEHICLES_COMPLETED';
export const FETCH_AVAILABLE_VEHICLES_FAILED =
  'FETCH_AVAILABLE_VEHICLES_FAILED';

export const DOWNLOAD_VEHICLE_REPORT = 'DOWNLOAD_VEHICLE_REPORT';
export const DOWNLOAD_VEHICLE_REPORT_COMPLETED =
  'DOWNLOAD_VEHICLE_REPORT_COMPLETED';
export const DOWNLOAD_VEHICLE_REPORT_FAILED = 'DOWNLOAD_VEHICLE_REPORT_FAILED';

export const DOWNLOAD_DOCK_VEHICLE_REPORT = 'DOWNLOAD_DOCK_VEHICLE_REPORT';
export const DOWNLOAD_DOCK_VEHICLE_REPORT_COMPLETED =
  'DOWNLOAD_DOCK_VEHICLE_REPORT_COMPLETED';
export const DOWNLOAD_DOCK_VEHICLE_REPORT_FAILED =
  'DOWNLOAD_DOCK_VEHICLE_REPORT_FAILED';

export const DOWNLOAD_BOOKING_REPORT_DATA = 'DOWNLOAD_BOOKING_REPORT_DATA';
export const DOWNLOAD_BOOKING_REPORT_DATA_COMPLETED =
  'DOWNLOAD_BOOKING_REPORT_DATA_COMPLETED';
export const DOWNLOAD_BOOKING_REPORT_DATA_FAILED =
  'DOWNLOAD_BOOKING_REPORT_DATA_FAILED';

export const fetchAvailableVehicles = (search = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_AVAILABLE_VEHICLES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchAvailableVehiclesURL(search),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_AVAILABLE_VEHICLES_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_AVAILABLE_VEHICLES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_AVAILABLE_VEHICLES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadAvailableVehiclesReport = (search = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_VEHICLE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.downloadAvailableVehiclesReportURL(search),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_VEHICLE_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_VEHICLE_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_VEHICLE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadDockReport = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_DOCK_VEHICLE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.downloadDockReportURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_DOCK_VEHICLE_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_DOCK_VEHICLE_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_DOCK_VEHICLE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadBookingReport = (currentTab) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_BOOKING_REPORT_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.downloadBookingReportURL(currentTab),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_BOOKING_REPORT_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_BOOKING_REPORT_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_BOOKING_REPORT_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_AVAILABLE_VEHICLES:
      return {...state, isFetching: true};
    case FETCH_AVAILABLE_VEHICLES_COMPLETED:
      return {
        ...state,
        isFetching: false,
        availableVehicles: payload.available_vehicles,
        search: payload.search,
      };
    case FETCH_AVAILABLE_VEHICLES_FAILED:
      return {...state, isFetching: false};
    case DOWNLOAD_VEHICLE_REPORT:
      return {...state, isDownloading: true};
    case DOWNLOAD_VEHICLE_REPORT_FAILED:
    case DOWNLOAD_VEHICLE_REPORT_COMPLETED:
      return {...state, isDownloading: false};
    default:
      return state;
  }
};
