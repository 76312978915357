import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE, TRIP_STATUS} from '../../../constants';
import * as _ from 'lodash';
import {Trips} from '../../../helpers/Urls';
import trips, {
  MARK_TRIP_AS_BEGIN_COMPLETED,
  MARK_TRIP_AS_CLOSED,
  MARK_TRIP_AS_CLOSED_FAILED,
  MARK_TRIP_AS_CLOSED_COMPLETED,
} from '../../trips';
import {
  FETCH_OPEN_TICKETS,
  FETCH_OPEN_TICKETS_COMPLETED,
  FETCH_OPEN_TICKETS_FAILED,
  FETCH_CLOSED_TICKETS,
  FETCH_CLOSED_TICKETS_COMPLETED,
  FETCH_CLOSED_TICKETS_FAILED,
} from '../tickets';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  new_trip_ids: [],
  in_transit_trip_ids: [],
  at_dest_trip_ids: [],
  dhanukaUnlockCount: 0,
  dhanukaUnlockFilter: false,
  simTrackingFilterCount: 0,
  marketAdvancePendingCount: 0,
  simTrackingPendingFilter: false,
  marketAdvancePendingFilter: false,

  isNewRequestMountedBool: false,
  isIntransitMountedBool: false,
  isAtDestinationMountedBool: false,
};

const FETCH_TRIPS_BY_STATUS = 'FETCH_TRIPS_BY_STATUS';
const SET_CURRENT_TABLE_DATA = 'SET_CURRENT_TABLE_DATA';
const FETCH_TRIPS_BY_STATUS_COMPLETED = 'FETCH_TRIPS_BY_STATUS_COMPLETED';
const FETCH_TRIPS_BY_STATUS_FAILED = 'FETCH_TRIPS_BY_STATUS_FAILED';
const FILTER_TRIP_FALSE = 'FILTER_TRIP_FALSE';
const FILTER_TRIP_TRUE = 'FILTER_TRIP_TRUE';
const APPLY_FILTER = 'APPLY_FILTER';

const DHANUKA_UNLOCK_FILTER = 'DHANUKA_UNLOCK_FILTER';
const SIM_TRACKING_PENDING_FILTER = 'SIM_TRACKING_PENDING_FILTER';
const MARKET_ADVANCE_PENDING_FILTER = 'MARKET_ADVANCE_PENDING_FILTER';

const CLEAR_FILTER = 'CLEAR_FILTER';

export const MARK_TRIP_AS_TERMINATE = 'MARK_TRIP_AS_TERMINATE';
export const MARK_TRIP_AS_TERMINATE_COMPLETED =
  'MARK_TRIP_AS_TERMINATE_COMPLETED';
export const MARK_TRIP_AS_TERMINATE_FAILED = 'MARK_TRIP_AS_TERMINATE_FAILED';

export const MARK_TRIP_AS_ARRIVED = 'MARK_TRIP_AS_ARRIVED';
export const MARK_TRIP_AS_ARRIVED_COMPLETED = 'MARK_TRIP_AS_ARRIVED_COMPLETED';
export const MARK_TRIP_AS_ARRIVED_FAILED = 'MARK_TRIP_AS_ARRIVED_FAILED';

export const IS_NEW_REQUESTED_MOUNTED = 'IS_NEW_REQUESTED_MOUNTED';
export const IS_IN_TRANSIT_MOUNTED = 'IS_IN_TRANSIT_MOUNTED';
export const IS_AT_DESTINATION_MOUNTED = 'IS_AT_DESTINATION_MOUNTED';

export const isNewRequestMount = (tabClicked = {}) => {
  return (dispatch) => {
    dispatch({
      type: IS_NEW_REQUESTED_MOUNTED,
      payload: tabClicked,
    });
  };
};

export const isInTransitMount = (tabClicked = {}) => {
  return (dispatch) => {
    dispatch({
      type: IS_IN_TRANSIT_MOUNTED,
      payload: tabClicked,
    });
  };
};

export const isAtDestinationMount = (tabClicked = {}) => {
  return (dispatch) => {
    dispatch({
      type: IS_AT_DESTINATION_MOUNTED,
      payload: tabClicked,
    });
  };
};

export const markTripAsArrived = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_TRIP_AS_ARRIVED});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.markTripAsArrivedURL(),
        data: requestData,
        isMAPI: true,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: MARK_TRIP_AS_ARRIVED_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: MARK_TRIP_AS_ARRIVED_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_TRIP_AS_ARRIVED_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const markTripAsTerminate = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_TRIP_AS_TERMINATE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.markTripAsTerminateURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: MARK_TRIP_AS_TERMINATE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: MARK_TRIP_AS_TERMINATE_FAILED,
          payload: {error: message},
        });
      }
      return response;
    } catch (err) {
      dispatch({
        type: MARK_TRIP_AS_TERMINATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripsByStatus = (tripStatus, search = '') => {
  console.log('filteredTrips 1', tripStatus);

  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIPS_BY_STATUS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchAllTripsByStatusURL(tripStatus) + search,
      });
      console.log('response --FETCH_TRIPS_BY_STATUS--', response);
      console.log('tripStatus ----', tripStatus);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_TRIPS_BY_STATUS_COMPLETED,
          payload: {...data, tripStatus},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIPS_BY_STATUS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIPS_BY_STATUS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const onTripsFetchedSuccessfully = (state, payload) => {
  const {
    tripStatus,
    trip_ids = [],
    Counts = {},
    filters = {},
    dhanuka_unlock_trips_count,
    pending_consent_trip_count,
    customer_advance_pending_trips_count,
  } = payload;
  if (tripStatus === TRIP_STATUS.NEW) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      simTrackingFilterCount: pending_consent_trip_count,
      marketAdvancePendingCount: customer_advance_pending_trips_count,
      new_trip_ids: trip_ids,
      Counts,
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (tripStatus == 'created?is_escalated=true') {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      simTrackingFilterCount: pending_consent_trip_count,
      new_trip_ids: trip_ids,
      Counts,
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (tripStatus === TRIP_STATUS.IN_TRANSIT_ESCALATED) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      dhanukaUnlockCount: dhanuka_unlock_trips_count,
      simTrackingFilterCount: pending_consent_trip_count,
      in_transit_trip_ids: trip_ids,
      Counts,
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (tripStatus === TRIP_STATUS.IN_TRANSIT) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      dhanukaUnlockCount: dhanuka_unlock_trips_count,
      simTrackingFilterCount: pending_consent_trip_count,
      marketAdvancePendingCount: customer_advance_pending_trips_count,
      in_transit_trip_ids: trip_ids,
      Counts,
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (tripStatus === TRIP_STATUS.AT_DESTINATION_ESCALATED) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      at_dest_trip_ids: trip_ids,
      Counts,
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (tripStatus === TRIP_STATUS.AT_DESTINATION) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      at_dest_trip_ids: trip_ids,
      marketAdvancePendingCount: customer_advance_pending_trips_count,
      Counts,
      searching: '',
      filter: {
        ...filters,
      },
    };
  }
};

const filterArray = (entry, array) => {
  const newArray = [...array];
  const index = newArray.indexOf(entry);
  if (index !== -1) {
    newArray.splice(index, 1);
  }
  return newArray;
};

const removeTripId = (state, payload) => {
  const {trip_id, Counts = {}} = payload;
  const {
    new_trip_ids = [],
    in_transit_trip_ids = [],
    at_dest_trip_ids = [],
  } = state;
  const newTripArray = filterArray(trip_id, new_trip_ids);
  const inTransitArray = filterArray(trip_id, in_transit_trip_ids);
  const atDestArray = filterArray(trip_id, at_dest_trip_ids);
  return {
    ...state,
    new_trip_ids: newTripArray,
    in_transit_trip_ids: inTransitArray,
    dhanukaUnlockCount: payload.dhanuka_unlock_trips_count,
    at_dest_trip_ids: atDestArray,
    isFetching: false,
    Counts,
  };
};

const addEntryToArray = (entry, array) => {
  const newArray = [...array];
  const index = newArray.indexOf(entry);
  if (index === -1) {
    newArray.unshift(entry);
  }
  return newArray;
};

export const filteredData = (searchInput = '') => (dispatch) => {
  console.log('filter trips called');
  if (!searchInput) {
    dispatch({
      type: FILTER_TRIP_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_TRIP_TRUE,
    payload: searchInput,
  });
};

export const currentTableData = (tableData = []) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_TABLE_DATA,
    payload: tableData,
  });
};

export const applyFilter = (filter = {}, tripStatus = '') => {
  return (dispatch) => {
    dispatch({
      type: APPLY_FILTER,
      payload: filter,
    });
  };
};

export const applyDhanukaUnlockFilter = (
  dhanukaFilter = false,
  tripStatus = '',
) => {
  return (dispatch) => {
    dispatch({
      type: DHANUKA_UNLOCK_FILTER,
      payload: dhanukaFilter,
    });
  };
};

export const applySIMTrackingFilter = (simTrackingFilter = false) => {
  return (dispatch) => {
    dispatch({
      type: SIM_TRACKING_PENDING_FILTER,
      payload: simTrackingFilter,
    });
  };
};

export const applyMarketAdvanceFilter = (marketAdvanceFilter = false) => {
  return (dispatch) => {
    dispatch({
      type: MARKET_ADVANCE_PENDING_FILTER,
      payload: marketAdvanceFilter,
    });
  };
};

export const clearFilter = (filter = {}) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FILTER,
    });
  };
};

const removeTripIdFromInTransitArray = (state, payload) => {
  const {trip_id, Counts = {}} = payload;
  const {in_transit_trip_ids = []} = state;
  const inTransitArray = filterArray(trip_id, in_transit_trip_ids);
  return {
    ...state,
    hasError: false,
    isFetching: false,
    in_transit_trip_ids: inTransitArray,
    Counts,
  };
};

const removeTripIdFromNewArray = (state, payload) => {
  const {trip_id, Counts = {}} = payload;
  const {new_trip_ids = []} = state;
  const newTripsArray = filterArray(trip_id, new_trip_ids);
  return {
    ...state,
    hasError: false,
    isFetching: false,
    new_trip_ids: newTripsArray,
    Counts,
  };
};

// const tripFilters = JSON.parse(localStorage.getItem('trip-filter'));
// const initialisedState = {
//   ...INITIAL_STATE,
//   filter: tripFilters || {},
// };

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_TRIPS_BY_STATUS:
    case MARK_TRIP_AS_TERMINATE:
    case MARK_TRIP_AS_CLOSED:
    case MARK_TRIP_AS_ARRIVED:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_OPEN_TICKETS_COMPLETED:
      return {
        new_trip_ids: [],
        in_transit_trip_ids: [],
        at_dest_trip_ids: [],
      };
    case FETCH_TRIPS_BY_STATUS_COMPLETED:
      return onTripsFetchedSuccessfully(state, payload);

    case MARK_TRIP_AS_TERMINATE_COMPLETED:
      return {
        ...state,
        isFetching: false,
        dhanukaUnlockCount: payload.dhanuka_unlock_trips_count,
      };

    case MARK_TRIP_AS_CLOSED_COMPLETED:
      return removeTripId(state, payload);
    case MARK_TRIP_AS_ARRIVED_COMPLETED:
      return removeTripId(state, payload);

    // case MARK_TRIP_AS_BEGIN_COMPLETED:
    //   return removeTripId(state, payload);
    case MARK_TRIP_AS_BEGIN_COMPLETED:
      return removeTripIdFromNewArray(state, payload);
    case MARK_TRIP_AS_TERMINATE_FAILED:
    case MARK_TRIP_AS_CLOSED_FAILED:
    case MARK_TRIP_AS_ARRIVED_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_TRIPS_BY_STATUS_FAILED:
      return {
        state: INITIAL_STATE,

        // ...state,
        isFetching: false,

        // new_trip_ids: [],
        // in_transit_trip_ids: [],
        // at_dest_trip_ids: [],
      };
    case SET_CURRENT_TABLE_DATA:
      return {
        ...state,
        currentTableData: payload,
      };
    case FILTER_TRIP_FALSE:
      return {
        ...state,
        searching: '',
        // filteredTrips: null,
      };
    case FILTER_TRIP_TRUE:
      return {
        ...state,
        searching: payload,
        // filteredTrips: payload,
      };
    case APPLY_FILTER:
      // localStorage.setItem('trip-filter', JSON.stringify(payload));
      return {
        ...state,
        filter: payload,
      };

    case DHANUKA_UNLOCK_FILTER:
      return {
        ...state,
        dhanukaUnlockFilter: payload,
      };

    case SIM_TRACKING_PENDING_FILTER:
      return {
        ...state,
        simTrackingPendingFilter: payload,
      };

    case MARKET_ADVANCE_PENDING_FILTER:
      return {
        ...state,
        marketAdvancePendingFilter: payload,
      };

    case CLEAR_FILTER:
      return {
        ...state,
        filter: {},
      };

    case IS_NEW_REQUESTED_MOUNTED:
      return {
        ...state,
        isNewRequestMountedBool: payload,
      };
    case IS_IN_TRANSIT_MOUNTED:
      return {
        ...state,
        isIntransitMountedBool: payload,
      };
    case IS_AT_DESTINATION_MOUNTED:
      return {
        ...state,
        isAtDestinationMountedBool: payload,
      };

    default:
      return state;
  }
};
