import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Auth} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetchingRoles: false,
  hasError: false,
  error: '',
  rolesData: [],
  alertMails: {},
  isRequestingRoleData: false,
};

const FETCH_ROLE_DATA = 'FETCH_ROLE_DATA';
const FETCH_ROLE_DATA_COMPLETED = 'FETCH_ROLE_DATA_COMPLETED';
const FETCH_ROLE_DATA_FAILED = 'FETCH_ROLE_DATA_FAILED';

export const UPDATE_ROLE_ALERT_MAIL = 'UPDATE_ROLE_ALERT_MAIL';
export const UPDATE_ROLE_ALERT_MAIL_COMPLETED =
  'UPDATE_ROLE_ALERT_MAIL_COMPLETED';
export const UPDATE_ROLE_ALERT_MAIL_FAILED = 'UPDATE_ROLE_ALERT_MAIL_FAILED';

export const FETCH_ALERT_MAILS_BY_ROLE = 'FETCH_ALERT_MAILS_BY_ROLE';
export const FETCH_ALERT_MAILS_BY_ROLE_COMPLETED =
  'FETCH_ALERT_MAILS_BY_ROLE_COMPLETED';
export const FETCH_ALERT_MAILS_BY_ROLE_FAILED =
  'FETCH_ALERT_MAILS_BY_ROLE_FAILED';

export const fetchRoles = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ROLE_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.fetchRolesURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_ROLE_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_ROLE_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ROLE_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchAlertMailsByRole = (roleId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ALERT_MAILS_BY_ROLE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.fetchAlertMailsByRoleURL(roleId),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_ALERT_MAILS_BY_ROLE_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_ALERT_MAILS_BY_ROLE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ALERT_MAILS_BY_ROLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateRoleAlertMail = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_ROLE_ALERT_MAIL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Auth.updateRoleAlertMailURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_ROLE_ALERT_MAIL_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_ROLE_ALERT_MAIL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_ROLE_ALERT_MAIL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_ROLE_DATA:
      return {
        ...state,
        isFetchingRoles: true,
        hasError: false,
        error: '',
      };
    case FETCH_ROLE_DATA_COMPLETED:
      return {
        ...state,
        isFetchingRoles: false,
        hasError: false,
        rolesData: payload,
      };
    case FETCH_ROLE_DATA_FAILED:
      return {
        ...state,
        isFetchingRoles: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_ALERT_MAILS_BY_ROLE:
      return {...state, isRequestingRoleData: true};
    case FETCH_ALERT_MAILS_BY_ROLE_COMPLETED: {
      return {...state, isRequestingRoleData: false, alertMails: payload};
    }
    case FETCH_ALERT_MAILS_BY_ROLE_FAILED:
      return {...state, isRequestingRoleData: false};
    default:
      return state;
  }
};
